import Layout from '@4c/layout';
import Card from '@bfly/ui2/Card';
import Popover from '@bfly/ui2/Popover';
import Text from '@bfly/ui2/Text';
import { FormattedMessage } from 'react-intl';

import { useVariation } from 'components/LaunchDarklyContext';

import { useMemberCounts } from '../utils/MemberCounts';
import DomainUserSeatsRingChart from './DomainUserSeatsRingChart';

export default function DomainUserSeatsIndicator() {
  const { numUsers, maxNumSeats, numAvailableSeats } = useMemberCounts();
  const numUsersWithDefault = numUsers ?? 0;
  const maxNumSeatsWithDefault = maxNumSeats ?? 0;
  const numAvailableSeatsWithDefault = numAvailableSeats ?? 0;
  const showMaxSeatModal = useVariation('null-max-seat-modal');

  return (
    <Card
      key="DomainUserSeatsIndicator"
      className="flex-row space-x-2 pr-5 pt-3 pb-1 ml-3 mt-3 items-center  justify-between"
    >
      <Layout direction="column" className="ml-2">
        <Layout>
          {showMaxSeatModal && !maxNumSeatsWithDefault ? (
            <div>
              <Card.Body className="text-headline">
                <Text as="div" variant="display-sm">
                  {numUsersWithDefault}
                </Text>
                <Text as="div" variant="body-bold">
                  <FormattedMessage
                    id="DomainUserSeatsIndicator.activeUsersText"
                    defaultMessage="{numUsersWithDefault, plural,
                  =1 { Active User}
                  other { Active Users}}"
                    values={{ numUsersWithDefault }}
                  />
                </Text>
              </Card.Body>
            </div>
          ) : (
            <>
              <div>
                <Card.Body>
                  <div className="pb-1 mb-2 border-b-2 border-grey-50">
                    <Text variant="lg" color="headline">
                      <FormattedMessage
                        id="DomainUserSeatsIndicator.header"
                        defaultMessage="Seats"
                      />
                    </Text>
                  </div>
                  <div>
                    <Text color="primary" variant="body-bold">
                      <FormattedMessage
                        key={numUsersWithDefault}
                        id="DomainUserSeatsIndicator.numActiveUsers"
                        defaultMessage="{numUsersWithDefault}"
                        values={{ numUsersWithDefault }}
                      />
                    </Text>
                    <Text>
                      <FormattedMessage
                        id="DomainUserSeatsIndicator.activeUsersText"
                        defaultMessage="{numUsersWithDefault, plural,
                      =1 { Active User}
                      other { Active Users}}"
                        values={{ numUsersWithDefault }}
                      />
                    </Text>
                  </div>
                  <div>
                    <Text>
                      <FormattedMessage
                        id="DomainUserSeatsIndicator.totalSeats"
                        defaultMessage="{maxNumSeatsWithDefault, plural,
                      =1 {{maxNumSeatsWithDefault} Total Seat}
                      other {{maxNumSeatsWithDefault} Total Seats}
                    }"
                        values={{ maxNumSeatsWithDefault }}
                      />
                    </Text>
                  </div>
                </Card.Body>
              </div>
              <Popover.Trigger
                data-bni-id="UserSeatsPopover"
                trigger="passive"
                placement="right"
                variant="dark"
                popover={
                  <div className="mt-1">
                    <Text as="div" variant="sm-bold" className="-mb-1">
                      <FormattedMessage
                        key={numAvailableSeatsWithDefault}
                        id="DomainUserSeatsIndicator.numAvailableSeats"
                        defaultMessage="{numAvailableSeatsWithDefault, plural,
                    =1 {{numAvailableSeatsWithDefault} Seat Available}
                    other {{numAvailableSeatsWithDefault} Seats Available}}"
                        values={{ numAvailableSeatsWithDefault }}
                      />
                    </Text>
                  </div>
                }
              >
                <div className="ml-5" data-bni-id="UserSeatsRingChart">
                  <DomainUserSeatsRingChart
                    activeUsers={numUsersWithDefault}
                    maxNumSeats={maxNumSeatsWithDefault}
                    numAvailableSeats={numAvailableSeatsWithDefault}
                  />
                </div>
              </Popover.Trigger>
            </>
          )}
        </Layout>
      </Layout>
    </Card>
  );
}
