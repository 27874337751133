import Layout from '@4c/layout';
import ArrowLeft from '@bfly/icons/ArrowLeft';
import Button from '@bfly/ui2/Button';
import FormCheckGroup from '@bfly/ui2/FormCheckGroup';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Modal from '@bfly/ui2/Modal';
import RelayInfiniteList from '@bfly/ui2/RelayInfiniteList';
import Subscription from '@bfly/ui2/Subscription';
import Text from '@bfly/ui2/Text';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import getNodes from '@bfly/utils/getNodes';
import useMergeState from '@restart/hooks/useMergeState';
import useMountEffect from '@restart/hooks/useMountEffect';
import { css } from 'astroturf';
import { useCallback, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from 'react-relay';

import EmptyWorklistScreen, {
  EmptyWorklistScreenFiltered,
} from 'components/EmptyWorklistScreen';
import { useVariation } from 'components/LaunchDarklyContext';
import ListCardContainer from 'components/ListCardContainer';
import PatientInfo from 'components/PatientInfo';
import RefreshModalityWorklistControl from 'components/RefreshModalityWorklistControl';
import WorklistItemDicomDocument from 'components/WorklistItemDicomDocument';
import WorklistItemHeader from 'components/WorklistItemHeader';
import WorklistSearchBar from 'components/WorklistSearchBar';
import WorklistSyncStatus from 'components/WorklistSyncStatus';
import examMessages from 'messages/examMessages';
import Analytics from 'utils/Analytics';
import { NodeFromConnection } from 'utils/typeUtils';

import { baseStudyInfo_selectedWorklistItemData$key as WorklistItemDataKey } from '../schemas/__generated__/baseStudyInfo_selectedWorklistItemData.graphql';
import FhirPatientSearch from './FhirPatientSearchModalContent';
import { AssociateExamWorklistItemModalContent_RefreshMutation as RefreshMutation } from './__generated__/AssociateExamWorklistItemModalContent_RefreshMutation.graphql';
import { AssociateExamWorklistItemModalContent_primaryWorklist$data as PrimaryWorklist } from './__generated__/AssociateExamWorklistItemModalContent_primaryWorklist.graphql';

const PAGE_SIZE = 34;

const DISCHARGED_STATE_DATA = [
  {
    id: 'ACTIVE' as const,
    label: <FormattedMessage {...examMessages.active} />,
  },
  {
    id: 'DISCHARGED' as const,
    label: <FormattedMessage {...examMessages.discharged} />,
  },
];

type WorklistDischargedState = typeof DISCHARGED_STATE_DATA[number];
type WorklistDischargedType = WorklistDischargedState['id'];

// TODO: Add patient filter properties here
type FilterState = { search: string; dischargedState: WorklistDischargedType };

type OnSelect =
  | ((worklistItem: WorklistItemDataKey) => Promise<void>)
  | ((worklistItem: WorklistItemDataKey) => void);

const refreshMutation = graphql`
  mutation AssociateExamWorklistItemModalContent_RefreshMutation(
    $input: RefreshModalityWorklistInput!
  ) {
    refreshModalityWorklistOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
      ... on RefreshModalityWorklistPayload {
        modalityWorklist {
          ...AssociateExamWorklistItemModalContent_primaryWorklist
        }
      }
    }
  }
`;

type WorklistItemNode = NodeFromConnection<
  PrimaryWorklist['worklistItemConnection']
>;

type SelectFromWorklistProps = {
  primaryWorklist: PrimaryWorklist;
  loading: boolean;
  filters: FilterState;
  selectedWorklist?: WorklistItemDataKey;
  setSelectedWorklist: (worklistItem: WorklistItemDataKey) => void;
  renderWorklistItem: (worklistItem: WorklistItemNode) => JSX.Element | null;
  relay: RelayPaginationProp;
};

interface Props {
  primaryWorklist: PrimaryWorklist;
  onHide: () => void;
  onBack: () => void;
  onSelect: OnSelect;
  relay: RelayPaginationProp;
}

/**
 * returns true if the date string isn't set to midnight UTC. This is because encounters from FHIR
 * may be dates or datetimes. A date converted to a datetime will have the time set to T00:00:00.
 */
function hasTimeComponent(dateStr?: string | null): dateStr is string {
  if (!dateStr?.includes('T')) {
    // no time component in the ISO8601 string
    return false;
  }

  return !dateStr.includes('T00:00:00');
}

/**
 * This hook encapsulates the state and logic needed by SelectFromWorklist
 * to handle displaying, fetching, and selecting a worklist item. Its meant
 * to be used by the parent component of SelectFromWorklist.
 */
function useSelectFromWorklist(
  props: Pick<Props, 'primaryWorklist' | 'relay'>,
): Omit<SelectFromWorklistProps, 'renderWorklistItem'> & {
  setFilters: (nextFilters: Partial<FilterState>) => void;
} {
  const { primaryWorklist, relay } = props;

  const [refresh] = useMutationWithError<RefreshMutation>(refreshMutation, {
    input: { modalityWorklistId: primaryWorklist.id },
  });

  const [loading, setLoading] = useState(false);

  const [filters, setFilterState] = useMergeState<FilterState>({
    search: '',
    dischargedState: 'ACTIVE',
  });

  useMountEffect(() => {
    if (primaryWorklist.refreshJob !== undefined) {
      refresh();
    }
  });

  const [selectedWorklist, setSelectedWorklist] =
    useState<WorklistItemDataKey>();

  const refetch = useCallback(
    (nextFilters: FilterState) => {
      setLoading(true);
      relay.refetchConnection(PAGE_SIZE, () => setLoading(false), {
        search: nextFilters.search,
        isDischarged: nextFilters.dischargedState === 'DISCHARGED',
      });
    },
    [relay],
  );

  const setFilters = useCallback(
    (nextFilters: Partial<FilterState>) => {
      refetch({ ...filters, ...nextFilters });
      setFilterState(nextFilters);
      if (nextFilters.dischargedState) {
        Analytics.track('addPatientDischargedStateChanged', {
          dischargedState: nextFilters.dischargedState,
          organizationId: primaryWorklist.organization?.id,
        });
      }
    },
    [refetch, filters, setFilterState, primaryWorklist.organization?.id],
  );

  return {
    primaryWorklist,
    loading,
    relay,
    filters,
    setSelectedWorklist,
    selectedWorklist,
    setFilters,
  };
}

/**
 * Handles displaying worklist items and selecting them. Should be paired with
 * `useSelectFromWorklist`.
 */
function SelectFromWorklist({
  primaryWorklist,
  loading,
  filters,
  selectedWorklist,
  setSelectedWorklist,
  renderWorklistItem,
  relay,
}: SelectFromWorklistProps) {
  const { worklistItemConnection } = primaryWorklist;
  const emptyWorklist = !worklistItemConnection?.edges?.length;
  const hasFilters = !!filters.search || filters.dischargedState !== 'ACTIVE';

  if (emptyWorklist && !loading && !hasFilters) {
    return <EmptyWorklistScreen />;
  }

  if (loading) {
    return (
      <ListCardContainer variant="secondary">
        <LoadingIndicator />
      </ListCardContainer>
    );
  }

  if (emptyWorklist) {
    return (
      <ListCardContainer variant="secondary">
        <EmptyWorklistScreenFiltered />
      </ListCardContainer>
    );
  }

  return (
    <ListCardContainer variant="secondary">
      <RelayInfiniteList key={0} relayPagination={relay} pageSize={PAGE_SIZE}>
        <FormCheckGroup
          type="radio"
          name="associateWorklistItem"
          value={selectedWorklist as any}
          onChange={(worklist) => setSelectedWorklist(worklist)}
          dataKey="id"
          renderItem={renderWorklistItem}
          direction="column"
          data={getNodes(primaryWorklist.worklistItemConnection)}
          variant="dark"
          className={css`
            label {
              align-items: center;
              width: 100%;
              display: flex;
              margin: 0 !important;

              span {
                width: 100%;
              }
            }
          `}
        />
      </RelayInfiniteList>
    </ListCardContainer>
  );
}

function ModalFooter({
  selectedWorklist,
  onSelect,
  onHide,
}: Pick<Props, 'onSelect' | 'onHide'> &
  Pick<SelectFromWorklistProps, 'selectedWorklist'>) {
  const handleSelectWorklist = () => {
    if (selectedWorklist) onSelect(selectedWorklist);
    else onHide();
  };

  return (
    <Modal.Footer>
      <Modal.ButtonGroup>
        <Button
          variant="primary"
          size="lg"
          disabled={!selectedWorklist}
          onClick={handleSelectWorklist}
        >
          <FormattedMessage {...examMessages.save} />
        </Button>
        <Button size="lg" variant="secondary" onClick={onHide}>
          <FormattedMessage {...examMessages.cancel} />
        </Button>
      </Modal.ButtonGroup>
    </Modal.Footer>
  );
}

function WorklistModalContent({
  onBack,
  onHide,
  onSelect,
  primaryWorklist,
  relay,
}: Props) {
  const { selectedWorklist, filters, setFilters, ...selectFromWorklistProps } =
    useSelectFromWorklist({
      primaryWorklist,
      relay,
    });

  // disable this if we have passed in search criteria
  const canUseDischargedPatients = useVariation('discharged-patients');
  const canEditPhi = !useVariation('disable-phi-entry');

  const backButton = canEditPhi && (
    <Button
      variant="text-secondary"
      onClick={onBack}
      className="px-0 mb-3"
      data-bni-id="BackFromWorklistButton"
    >
      <ArrowLeft width={20} className="mr-2" />
      <FormattedMessage {...examMessages.editPatient} />
    </Button>
  );

  const searchComponents = (
    <>
      {primaryWorklist.type === 'EncounterBasedWorklist' &&
        canUseDischargedPatients && (
          <FormCheckGroup<WorklistDischargedState>
            type="radio"
            name="isDischarged"
            value={filters.dischargedState as any}
            onChange={(item) => {
              setFilters({ dischargedState: item.id });
            }}
            dataKey="id"
            renderItem={(item) => item.label}
            direction="row"
            data={DISCHARGED_STATE_DATA}
            variant="dark"
          />
        )}
      <WorklistSearchBar
        autoFocus
        worklist={primaryWorklist}
        defaultValue={filters.search}
        variant="secondary"
        onChange={(search) => {
          setFilters({ search });
          if (search) {
            Analytics.track('addPatientSearch', {
              primaryWorklistId: primaryWorklist.id,
              organizationId: primaryWorklist.organization?.id,
            });
          }
        }}
      />
    </>
  );

  return (
    <>
      <div className="px-5 pt-3">
        {backButton}
        <Modal.Title className="flex justify-between">
          <FormattedMessage {...examMessages.chooseFromWorklist} />
          {primaryWorklist.refreshJob !== undefined && (
            <Layout pad={3} align="center">
              {primaryWorklist.refreshJob?.completedAt && (
                <Text variant="sm" color="body">
                  <WorklistSyncStatus modalityWorklist={primaryWorklist} />
                </Text>
              )}
              <RefreshModalityWorklistControl
                size="flush"
                modalityWorklist={primaryWorklist}
              />
              <Subscription
                subscription={graphql`
                  subscription AssociateExamWorklistItemModalContent_Subscription(
                    $input: ModalityWorklistRefreshedSubscriptionInput!
                  ) {
                    modalityWorklistRefreshed(input: $input) {
                      modalityWorklist {
                        ...AssociateExamWorklistItemModalContent_primaryWorklist
                      }
                    }
                  }
                `}
                input={{ modalityWorklistId: primaryWorklist.id }}
              />
            </Layout>
          )}
        </Modal.Title>
      </div>

      <Modal.Body>
        {searchComponents}
        <SelectFromWorklist
          {...selectFromWorklistProps}
          filters={filters}
          selectedWorklist={selectedWorklist}
          renderWorklistItem={(worklistItem) => (
            <>
              <WorklistItemHeader
                worklistItem={worklistItem!}
                titleTextVariant="body-bold"
              />
              <Layout direction="column" className="mb-6 mt-2">
                <div>
                  <PatientInfo
                    patient={worklistItem}
                    accessionNumber={worklistItem!.accessionNumber}
                  />
                </div>
                <WorklistItemDicomDocument worklistItem={worklistItem!} />
              </Layout>
            </>
          )}
        />
      </Modal.Body>
      <ModalFooter
        onHide={onHide}
        onSelect={onSelect}
        selectedWorklist={selectedWorklist}
      />
    </>
  );
}

function FhirWorklistModelContent({
  onBack,
  onHide,
  onSelect,
  primaryWorklist,
  relay,
}: Props) {
  const { selectedWorklist, filters, setFilters, ...selectFromWorklistProps } =
    useSelectFromWorklist({
      primaryWorklist,
      relay,
    });

  const canEditPhi = !useVariation('disable-phi-entry');

  // search holds the patient's fhirId
  const patientIdentifier = filters.search;

  const backFromWorklistButton = canEditPhi && (
    <Button
      variant="text-secondary"
      onClick={onBack}
      className="px-0 mb-3"
      data-bni-id="BackFromWorklistButton"
    >
      <ArrowLeft width={20} className="mr-2" />
      <FormattedMessage {...examMessages.editPatient} />
    </Button>
  );

  const backToPatientSearch = patientIdentifier && (
    <Button
      variant="text-secondary"
      onClick={() => {
        setFilters({ search: undefined });
      }}
      className="px-0 mb-3"
      data-bni-id="BackFromWorklistButton"
    >
      <ArrowLeft width={20} className="mr-2" />
      <FormattedMessage {...examMessages.back} />
    </Button>
  );

  const backButton = backToPatientSearch || backFromWorklistButton;

  if (!patientIdentifier) {
    return (
      <FhirPatientSearch
        onHide={onHide}
        onBack={onBack}
        onSelect={(data) => {
          // TODO: We should use `setFilters` here to search
          // for encounters relating to the worklist item
          setFilters({ search: data.patientFhirId! });
        }}
      />
    );
  }

  const naMessage = (
    <FormattedMessage id="exam.worklist.fhir.na" defaultMessage="N/A" />
  );

  return (
    <>
      <div className="px-5 pt-3">
        {backButton}
        <Modal.Title className="flex justify-between">
          <FormattedMessage {...examMessages.chooseFromWorklist} />
        </Modal.Title>
      </div>

      <Modal.Body>
        <SelectFromWorklist
          {...selectFromWorklistProps}
          filters={filters}
          selectedWorklist={selectedWorklist}
          renderWorklistItem={(worklistItem) => (
            <Layout direction="column" className="mb-6 mt-2">
              <div data-bni-id="fhir-encounter">
                <Text variant="body-bold">
                  {hasTimeComponent(worklistItem!.visitStartDate) ? (
                    <FormattedDateTime value={worklistItem!.visitStartDate!} />
                  ) : (
                    <FormattedDate
                      value={worklistItem!.visitStartDate!}
                      timeZone="UTC"
                    />
                  )}
                  {worklistItem!.visitEndDate && (
                    <>
                      {' - '}
                      {hasTimeComponent(worklistItem!.visitEndDate) ? (
                        <FormattedDateTime
                          value={worklistItem!.visitEndDate!}
                        />
                      ) : (
                        <FormattedDate
                          value={worklistItem!.visitEndDate!}
                          timeZone="UTC"
                        />
                      )}
                    </>
                  )}
                </Text>
                <div>
                  <Text variant="body">
                    <FormattedMessage
                      id="exam.worklist.fhirEncounter.department"
                      defaultMessage="Dept: "
                      description="Short for 'hospital department'"
                    />
                    {worklistItem?.visitLocation || naMessage}
                  </Text>
                </div>
                <div>
                  <Text variant="body">
                    <FormattedMessage
                      id="exam.worklist.fhirEncounter.type"
                      defaultMessage="Type: "
                    />
                    {worklistItem?.visitTypes?.join(', ') || naMessage}
                  </Text>
                </div>
                <div>
                  <Text variant="body">
                    <FormattedMessage
                      id="exam.worklist.fhirEncounter.visitNumber"
                      defaultMessage="Encounter number: "
                    />
                    {worklistItem?.visitNumber}
                  </Text>
                </div>
              </div>
            </Layout>
          )}
        />
      </Modal.Body>
      <ModalFooter
        onHide={onHide}
        onSelect={onSelect}
        selectedWorklist={selectedWorklist}
      />
    </>
  );
}

function AssociateExamWorklistItemModalContent(props: Props) {
  const isFhir = props.primaryWorklist!.type === 'FhirIntegration';

  if (isFhir) {
    return <FhirWorklistModelContent {...props} />;
  }

  return <WorklistModalContent {...props} />;
}

export default createPaginationContainer(
  AssociateExamWorklistItemModalContent,
  {
    primaryWorklist: graphql`
      fragment AssociateExamWorklistItemModalContent_primaryWorklist on WorklistInterface
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 34 } # PAGE_SIZE.
        cursor: { type: "String" }
        search: { type: "String", defaultValue: "" }
        isDischarged: { type: "[Boolean!]", defaultValue: false }
      ) {
        type: __typename
        ... on Node {
          id
        }
        ... on WorklistInterface {
          ...WorklistSearchBar_worklist
          organization {
            id
          }
        }
        ... on ModalityWorklist {
          id
          refreshJob {
            completedAt
          }
          ...RefreshModalityWorklistControl_modalityWorklist
          ...WorklistSyncStatus_modalityWorklist
        }
        name
        worklistItemConnection(
          first: $count
          after: $cursor
          search: $search
          isDischarged: $isDischarged
        )
          @connection(
            key: "Worklist_worklistItemConnection"
            filters: ["search", "isDischarged"]
          ) {
          edges {
            node {
              ... on Node {
                id
              }
              ... on FhirEncounter {
                visitTypes
                visitStartDate
                visitEndDate
                visitLocation
                visitNumber
              }
              accessionNumber
              ...WorklistItemHeader_worklistItem
              ...WorklistItemDicomDocument_worklistItem
              ...PatientInfo_patient
              # eslint-disable-next-line relay/must-colocate-fragment-spreads
              ...baseStudyInfo_selectedWorklistItemData
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ primaryWorklist }) =>
      primaryWorklist.worklistItemConnection,
    getVariables: (
      { primaryWorklist },
      { count, cursor },
      { search, isDischarged },
    ) => ({
      worklistId: primaryWorklist.id,
      count,
      cursor,
      search,
      isDischarged,
    }),
    query: graphql`
      query AssociateExamWorklistItemModalContentPaginationQuery(
        $worklistId: ID!
        $count: Int!
        $cursor: String
        $search: String
        $isDischarged: [Boolean!]
      ) {
        primaryWorklist: node(id: $worklistId) {
          ...AssociateExamWorklistItemModalContent_primaryWorklist
            @arguments(
              count: $count
              cursor: $cursor
              search: $search
              isDischarged: $isDischarged
            )
        }
      }
    `,
  },
);
