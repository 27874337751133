import RingChart from '@bfly/ui2/RingChart';
import Text from '@bfly/ui2/Text';
import { colorVariables } from '@bfly/ui2/tailwind/colors';
import { FormattedMessage } from 'react-intl';

interface DomainUserSeatsRingChartProps {
  maxNumSeats: number;
  activeUsers: number;
  numAvailableSeats: number;
}

const DomainUserSeatsRingChart = ({
  maxNumSeats,
  activeUsers,
  numAvailableSeats,
}: DomainUserSeatsRingChartProps) => {
  const availableSeatsPercent =
    numAvailableSeats && maxNumSeats
      ? Math.round((numAvailableSeats / maxNumSeats) * 100)
      : 0;
  return (
    <RingChart
      spacerColor={colorVariables.grey[50]}
      spacerWidth={8}
      spacerGap={8}
      radius={40}
      axisColor={colorVariables.grey[80]}
      axisWidth={30}
      axisShadow={1}
      total={maxNumSeats}
      className="w-24"
      data={[
        {
          value: activeUsers,
          color: colorVariables.blue.DEFAULT,
        },
      ]}
    >
      <Text color="headline" variant="sm">
        <div className="mb-1 leading-[100%]">
          <FormattedMessage
            defaultMessage="{availableSeatsPercent}% of Seats Available"
            values={{ availableSeatsPercent }}
          />
        </div>
      </Text>
    </RingChart>
  );
};

export default DomainUserSeatsRingChart;
